<template>
  <div>
    <div class="dispatch-left" style="width: 200px;">
      <Row>
        <i-col span="24" class="p-l-10 p-b-5">
          <label class="text-24">四川高速</label><br/>
          <label>加油站站点信息分布图</label>
        </i-col>
      </Row>
      <Divider dashed style="margin: 0 0 2px 0;"/>
      <div class="dispatch-left-title">高速线路</div>

      <CheckboxGroup v-model="checkAssetIds" class="p-l-10" @on-change="loadMapMarker">
        <div v-for="item in assetList" :key="item.id">
          <Checkbox class="p-t-5" :label="item.id">{{item.name}}</Checkbox>
        </div>
      </CheckboxGroup>
    </div>

    <div v-show="showRightComponnet" class="gas-station-info">
      <h2 class="text-center">{{curStationInfo.name}}</h2>
      <div class="m-t-5">
        <img style="max-width: 230px;" src="../../assets/images/加油站.png"/>
      </div>
      <Row class="p-t-5">
        <i-col span="8" class="title">所在高速</i-col>
        <i-col span="16">{{curStationInfo.assetName}}</i-col>
      </Row>
      <Row class="p-t-5">
        <i-col span="8" class="title">所在服务区</i-col>
        <i-col span="16">{{curStationInfo.street}}</i-col>
      </Row>
      <Row class="p-t-5">
        <i-col span="8" class="title">地址</i-col>
        <i-col span="16">{{curStationInfo.location}}</i-col>
      </Row>
      <Row class="p-t-5">
        <i-col span="8" class="title">总车次</i-col>
        <i-col span="16">{{formatNumer(340524)}} 辆</i-col>
      </Row>

    </div>

    <!-- 地图 -->
    <div class="map-container" id="gasStationMap" tabindex="0"></div>
  </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getStorage } from '@/utils/storage'
import { getPublisherCompanyAssetList } from '@/api/product/device'
import { getStationByAssetIds } from '@/api/product/station'
import { toNumber } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      amapObject: null, // 地图对象
      center: [104.067923463, 30.6799428454],
      opts: {
        subdistrict: 0,
        extensions: 'all',
        level: 'province'
      },

      assetList: [],
      checkAssetIds: [],

      blueMarker: require('@/assets/images/metro_bluemarker.png'),
      redMarker: require('@/assets/images/metro_redmarker.png'),
      stationMarkers: [],

      showRightComponnet: false,
      curStationInfo: {},
      historyMarker: null
    }
  },
  mounted () {
    document.getElementById('gasStationMap').style.height = document.body.clientHeight + 'px'
    this.initMap()
    this.initPageData()
  },
  computed: {
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  methods: {
    initMap () {
      const _self = this
      // 获取主题皮肤，设置地图底色
      let mapStyleUrl = ''
      if (this.themeIsDark) {
        mapStyleUrl = 'amap://styles/573d8aba3e7be0834cd757cac73ac0ae'
      }

      lazyAMapApiLoaderInstance.load().then(() => {
        AMap.plugin('AMap.DistrictSearch', function () {
          // 利用行政区查询获取边界构建mask路径
        // 也可以直接通过经纬度构建mask路径
          var district = new AMap.DistrictSearch(_self.opts)
          district.search('四川省', function (status, result) {
            // 设置覆盖的隐藏区域，仅显示出四川
            var bounds = result.districtList[0].boundaries
            var mask = []
            for (var i = 0; i < bounds.length; i += 1) {
              mask.push([bounds[i]])
            }
            _self.amapObject = new AMap.Map('gasStationMap', {
              mask: mask,
              resizeEnable: true,
              center: new AMap.LngLat(_self.center[0], _self.center[1]),
              mapStyle: mapStyleUrl,
              zoom: 8,
              zooms: [6, 14],
              disableSocket: true
            })

            // 开始地图描边
            for (var j = 0; j < bounds.length; j += 1) {
            // eslint-disable-next-line no-new
              new AMap.Polyline({
                path: bounds[j],
                strokeColor: '#3078AC',
                strokeWeight: 2,
                strokeOpacity: 1,
                map: _self.amapObject
              })
            }
          })
        })
      })
    },
    initPageData () {
      const postData = {
        companyId: this.$store.getters.token.userInfo.companyId,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      getPublisherCompanyAssetList(postData).then(res => {
        this.assetList = res.filter(x => x.type === 5)
        this.checkAssetIds = this.assetList.map(x => x.id)
        this.loadMapMarker()
      })
    },
    loadMapMarker () {
      if (this.amapObject) {
        // 清空全部标注
        this.showRightComponnet = false
        this.amapObject.remove(this.stationMarkers)
        this.beginInitMapMarker()
      } else {
        setTimeout(() => {
          this.loadMapMarker()
        }, 500)
      }
    },
    beginInitMapMarker () {
      const postData = {
        assetIds: JSON.stringify(this.checkAssetIds)
      }

      getStationByAssetIds(postData).then(res => {
        this.stationMarkers = []
        let marker

        res.forEach(item => {
          marker = new AMap.Marker({
            title: item.location,
            position: new AMap.LngLat(item.gdLongitude, item.gdLatitude),
            offset: new AMap.Pixel(0, 0),
            topWhenClick: true, // 点击标注后，置顶
            icon: new AMap.Icon({
              size: new AMap.Size(12, 15), // 图标尺寸
              image: this.blueMarker, // Icon的图像
              imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(12, 15) // 根据所设置的大小拉伸或压缩图片
            })
          })
          // 给marker添加属性
          marker.setExtData(item)
          // 给marker添加点击事件
          marker.on('click', this.markerClick)

          this.stationMarkers.push(marker)
        })

        // 开始添加标注到地图上
        this.amapObject.add(this.stationMarkers)
        this.amapObject.setFitView()
      })
    },
    markerClick (e) {
      // e.target.getExtData() 获取点击的marker属性 然后显示当前marker详情
      const curMarker = e.target

      this.curStationInfo = curMarker.getExtData()
      this.showRightComponnet = true

      // 还原已经被变更的颜色
      if (this.historyMarker) {
        this.historyMarker.setIcon(new AMap.Icon({
          size: new AMap.Size(12, 15), // 图标尺寸
          image: this.blueMarker, // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(12, 15) // 根据所设置的大小拉伸或压缩图片
        }))
      }

      // 变更当前标注颜色
      curMarker.setIcon(new AMap.Icon({
        size: new AMap.Size(12, 15), // 图标尺寸
        image: this.redMarker, // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(12, 15) // 根据所设置的大小拉伸或压缩图片
      }))
      this.historyMarker = curMarker
    },
    formatNumer (number) {
      return toNumber(number)
    }
  }
}
</script>

<style>
@import "../../assets/css/dispatch.css";
.map-container {
  width:100%;
}
.ivu-checkbox-inner {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #aeaeae;
  border-radius: 2px;
  background-color: #17233d;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
</style>
